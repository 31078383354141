// @flow
import type { Media } from 'src/utils/types'
import type { Module } from 'storeon'
import { getMediaByLocation } from 'src/services/wordpress'

export type MediaState = {
  media: Media[],
  isLoadingMedia: boolean,
  locationId: string,
  loadMediaError: string
}

export const mediaModule: Module<MediaState> = store => {
  store.on('@init', () => ({
    media: [],
    locationId: '',
    isLoadingMedia: false,
    loadMediaError: ''
  }))

  store.on('media/load', async (state, locationId: string) => {
    try {
      if (locationId !== state.locationId) {
        store.dispatch('media/isLoading')
        const { data } = await getMediaByLocation(locationId)
        store.dispatch('media/loaded', { mediaItems: data, locationId })
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('media/isLoading', state => ({
    isLoadingMedia: true
  }))
  store.on(
    'media/loaded',
    (state, { mediaItems, locationId }: MediaLoadedEvent) => ({
      media: mediaItems,
      locationId,
      isLoadingMedia: false
    })
  )
}

type MediaLoadedEvent = {
  mediaItems: Media[],
  locationId: string
}
