// @flow

import React from 'react'
import ReactDOM from 'react-dom'
import StoreContext from 'storeon/react/context'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from 'src/theme'
import Routes from 'src/routes'
import { store } from 'src/state/store'
import { register } from 'src/serviceWorker'

const App = () => (
  <StoreContext.Provider value={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  </StoreContext.Provider>
)

if (process.env.NODE_ENV !== 'production') {
  import('react-axe').then(axe => {
    axe.default(React, ReactDOM, 1000)
    //$FlowFixMe
    ReactDOM.render(<App />, document.getElementById('root'))
  })
} else {
  //$FlowFixMe
  ReactDOM.render(<App />, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register()
