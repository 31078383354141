// @flow
import type { Location } from 'src/utils/types'
import type { Module } from 'storeon'
import { getLocationList } from 'src/services/wordpress'
import { transformLocations } from 'src/utils/transformers'

export type LocationState = {
  locations: Location[],
  isLoadingLocations: boolean,
  loadLocationsError: string
}

export const locationModule: Module<LocationState> = store => {
  store.on('@init', state => ({
    locations: [],
    isLoadingLocations: false,
    loadLocationsError: ''
  }))
  store.on('locations/load', async state => {
    try {
      if (!state.locations.length) {
        store.dispatch('locations/isLoading')
        const { data } = await getLocationList()
        const locations = transformLocations(data)
        store.dispatch('locations/loaded', locations)
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('locations/isLoading', state => ({ isLoadingLocations: true }))
  store.on('locations/loaded', (state, locations: Location[]) => ({
    locations,
    isLoadingLocations: false
  }))
}
