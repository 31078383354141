// @flow
import React, { useEffect } from 'react'
import type { LocationState } from 'src/state/locations'
import type { CategoryState } from 'src/state/categories'
import { ExploreList } from '@momentous/cea-component-library'
import { useHistory } from 'react-router-dom'
import useStoreon from 'storeon/react'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { FullScreenLoading } from '@momentous/cea-component-library/build/components/atoms/loading/FullScreen'
import { UNIVERSITY_COORDINATES, UNIVERSITY_NAME } from 'src/settings'

export const ExploreListPage = () => {
  const history = useHistory()
  const { analytics } = useAnalytics()
  const {
    dispatch,
    locations,
    categories,
    isLoadingLocations,
    isLoadingCategories
  } = useStoreon<LocationState & CategoryState>(
    'locations',
    'categories',
    'isLoadingLocations',
    'isLoadingCategories'
  )

  useEffect(() => {
    dispatch('locations/load')
    dispatch('categories/load')
  }, [dispatch])

  const loading = isLoadingLocations || isLoadingCategories

  return loading ? (
    <FullScreenLoading loading />
  ) : (
    <ExploreList
      universityName={UNIVERSITY_NAME}
      universityCoordinates={UNIVERSITY_COORDINATES}
      categories={categories}
      locationList={locations}
      isLoading={loading}
      onLocationClick={loc => {
        analytics.logEvent('location_selected', {
          location_name: loc.name,
          location_category: loc.categories.length
            ? loc.categories.reduce((prev, next) => `${prev}, ${next}`)
            : ''
        })
        history.push(`/explore/location/${loc.id}`)
      }}
      onMapClick={isMapOpen => {
        //track when map view is used
        isMapOpen && analytics.logEvent('explore_map_view')
        history.push(isMapOpen ? '/explore/list' : '/explore/map')
      }}
      onBack={() => history.goBack()}
    />
  )
}
