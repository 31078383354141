// @flow
import type { WPMenuItem, WPLocation } from 'src/services/wordpress'
import type { MenuItem, Tour, Location } from 'src/utils/types'
import { toSlug, decodeHTMLEntities } from 'src/utils/formatting'
import type { ExploreCategory } from '@momentous/cea-component-library'

import {
  FoodIcon,
  CampusHighlightsIcon,
  EntertainmentIcon,
  ServicesIcon,
  HousingIcon,
  LodgingIcon,
  EventsIcon
} from '@momentous/cea-component-library'

type MenusTransformer = (WPMenuItem[]) => MenuItem[]

export const transformMenus: MenusTransformer = menuItems =>
  menuItems.map(item => {
    const parent = {
      id: item.ID,
      label: item.title,
      slug: item.slug || toSlug(item.title),
      childItems: item.child_items?.map(childItem => ({
        id: childItem.ID,
        label: childItem.title,
        slug: childItem.slug || toSlug(childItem.title),
        postId: childItem.object_id
      }))
    }
    // make sure "parent" field of child items refers to the parent object
    parent.childItems = parent.childItems?.map(childItem => ({
      ...childItem,
      parent: { ...parent }
    }))
    return parent
  })

type DefaultTourTransformer = (Tour[]) => Tour[]

export const transformDefaultTours: DefaultTourTransformer = tours =>
  tours.map(tour => {
    tour.isCustom = false
    return tour
  })

type LocationTransformer = (WPLocation[]) => Location[]

export const transformLocations: LocationTransformer = locations =>
  locations.map(loc => ({
    ...loc,
    categories: loc.categories.map(cat => decodeHTMLEntities(cat)),
    cordinates: {
      lat: Number(loc.cordinates.lat),
      lng: Number(loc.cordinates.lng)
    }
  }))

type CategoryTransformer = (string[]) => ExploreCategory[]
export const transformCategories: CategoryTransformer = (
  categories: string[]
) =>
  categories
    .filter(cat => !cat.includes('Campus Tour'))
    .map(cat => cat.replace(/&amp;/g, '&'))
    .map(cat => ({
      label: cat,
      icon: inferCategoryIcon(cat)
    }))

const inferCategoryIcon = (category: string) => {
  switch (true) {
    case category.includes('Highlights'):
      return CampusHighlightsIcon
    case category.includes('Services'):
      return ServicesIcon
    case category.includes('Entertain'):
      return EntertainmentIcon
    case category.includes('Housing'):
      return HousingIcon
    case category.includes('Lodging'):
      return LodgingIcon
    case category.includes('Events') || category.includes('Attraction'):
      return EventsIcon
    case category.includes('Food') ||
      category.includes('Dining') ||
      category.includes('Restauarant'):
      return FoodIcon
    default:
      return CampusHighlightsIcon
  }
}
