// @flow
import React, { useEffect } from 'react'
import useStoreon from 'storeon/react'
import { useParams, useHistory } from 'react-router-dom'
import type { MenuState } from 'src/state/menus'
import { ContentView } from '@momentous/cea-component-library'
import { getLabelFromSlug } from 'src/utils/formatting'
import { UNIVERSITY_LOGO, UNIVERSITY_NAME, DOMAIN } from 'src/settings'

export const ContentViewPage = () => {
  const { section, postSlug } = useParams()
  const history = useHistory()
  const { dispatch, menus } = useStoreon<MenuState>('menus')

  useEffect(() => {
    // if section exists, we are in the content menu (not contact tab)
    if (section && !menus.length) {
      history.push(`/menu/${String(section)}`)
    }
  }, [dispatch, history, menus, postSlug, section])

  return (
    <>
      {postSlug && (
        <ContentView
          domain={DOMAIN}
          schoolIcon={UNIVERSITY_LOGO}
          universityName={UNIVERSITY_NAME}
          sectionTitle={getLabelFromSlug(section, menus)}
          postSlug={postSlug}
          onBack={() => history.goBack()}
        />
      )}
    </>
  )
}
