// @flow
import React, { useEffect } from 'react'
import { ExploreCategories } from '@momentous/cea-component-library'
import type { CategoryState } from 'src/state/categories'
import useStoreon from 'storeon/react'
import { UNIVERSITY_LOGO, UNIVERSITY_NAME } from 'src/settings'

export const ExploreCategoriesPage = () => {
  const {
    dispatch,
    categories,
    isLoadingCategories
  } = useStoreon<CategoryState>('categories', 'isLoadingCategories')

  useEffect(() => {
    dispatch('categories/load')
  }, [dispatch])

  return (
    <ExploreCategories
      schoolIcon={UNIVERSITY_LOGO}
      universityName={UNIVERSITY_NAME}
      loading={isLoadingCategories}
      categoryLinks={categories}
    />
  )
}
