// @flow
import type { Module } from 'storeon'
import { getCategories } from 'src/services/wordpress'
import { transformCategories } from 'src/utils/transformers'

export type CategoryState = {
  categories: string[],
  isLoadingCategories: boolean,
  loadCategoriesError: string
}

export const categoryModule: Module<CategoryState> = store => {
  store.on('@init', state => ({
    categories: [],
    isLoadingCategories: false,
    loadCategoriesError: ''
  }))
  store.on('categories/load', async state => {
    try {
      if (!state.categories.length) {
        store.dispatch('categories/isLoading')
        const { data } = await getCategories()
        store.dispatch('categories/loaded', transformCategories(data))
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('categories/isLoading', state => ({ isLoadingCategories: true }))
  store.on('categories/loaded', (state, categories: string[]) => ({
    categories,
    isLoadingCategories: false
  }))
}
