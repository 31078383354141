// @flow
import type { Module } from 'storeon'

export type ErrorState = {
  showError: boolean,
  errorMessage: string
}

export const errorModule: Module<ErrorState> = store => {
  store.on('@init', () => ({
    showError: false,
    errorMessage: ''
  }))

  store.on('error/404', state => ({
    showError: true,
    errorMessage: `The content you requested couldn't be found. World could be ending, or you may just have a bad link...`
  }))
  store.on('error/401', state => ({
    showError: true,
    errorMessage: `It looks like you are trying to access something without permission. Sneaky one aren't you?`
  }))
  store.on('error/reset', state => ({ showError: false, errorMessage: '' }))
}
