// @flow
import type { MenuItem } from 'src/utils/types'
// gets a fixed number of decimal places
// credit to https://stackoverflow.com/a/11818658

export const toFixed = (num: number, fixed: number) => {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  //$FlowFixMe
  return num.toString().match(re)[0]
}

export const toSlug = (s: string) => {
  return s
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}

// label may be "Student Life" but path is "studentlife"
export const getLabelFromSlug = (slug: ?string, menuItems: MenuItem[]) =>
  menuItems.find(menuItem => menuItem.slug === slug)?.label || ''

export const decodeHTMLEntities = (str: string) => {
  // strip script/html tags
  str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '')
  str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '')
  return str
}
