/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useEffect } from 'react'
import type { TourState } from 'src/state/tours'
import { TourList } from '@momentous/cea-component-library'
import { useHistory } from 'react-router-dom'
import useStoreon from 'storeon/react'
import type { Tour, Location } from 'src/utils/types'
import {
  UNIVERSITY_LOGO,
  UNIVERSITY_NAME,
  UNIVERSITY_COORDINATES
} from 'src/settings'

export const TourListPage = () => {
  const history = useHistory()

  const {
    dispatch,
    activeTour,
    destination,
    customTours,
    defaultTours,
    isLoadingDefaultTours
  } = useStoreon<TourState>(
    'activeTour',
    'destination',
    'defaultTours',
    'customTours',
    'isLoadingDefaultTours'
  )

  useEffect(() => {
    if (!defaultTours.length) dispatch('tours/load')
  }, [dispatch])

  const tours = customTours.concat(defaultTours)

  return (
    <>
      <TourList
        schoolIcon={UNIVERSITY_LOGO}
        universityCoordinates={UNIVERSITY_COORDINATES}
        universityName={UNIVERSITY_NAME}
        tours={tours}
        destination={destination}
        activeTour={activeTour}
        onBack={() => history.goBack()}
        setActiveTour={(
          activeTour: Tour | null,
          destination: Location | null
        ) => dispatch('tours/set-active', { activeTour, destination })}
        onTourCreate={title => {
          dispatch('tours/save', {
            id: `${Date.now()}${Math.random()}`.replace('.', ''),
            isCustom: true,
            title,
            locations: []
          })
        }}
        // this actually refers to the current destination
        onTourClick={id => history.push(`/explore/location/${id}`)}
        setNavOpen={isHidden => dispatch('navigation/isHidden', isHidden)}
        isLoadingTours={isLoadingDefaultTours}
        onTourEdit={id => history.push(`/tours/edit/${id}`)}
      />
    </>
  )
}
