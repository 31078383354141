// @flow
import React from 'react'
import type { TourState } from 'src/state/tours'
import { EditTour } from '@momentous/cea-component-library'
import { useHistory, useParams } from 'react-router-dom'
import useStoreon from 'storeon/react'

export const EditTourPage = () => {
  const history = useHistory()
  const { id } = useParams()

  const { dispatch, customTours } = useStoreon<TourState>('customTours')

  const tour = customTours.find(tour => tour.id === id)

  if (!tour) {
    history.push('/tours')
  }

  return (
    <>
      <EditTour
        onBack={() => history.goBack()}
        onAddEntry={() => history.push('/')}
        onEditTour={locs => {
          dispatch('tours/save', {
            ...tour,
            locations: locs
          })
        }}
        onTourItemClick={id => {
          history.push(`/explore/location/${id}`)
        }}
        tour={tour}
      />
    </>
  )
}
