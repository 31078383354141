// @flow
import type { Module } from 'storeon'

export type NavigationState = {
  path: string,
  isHidden: boolean
}

export const navigationModule: Module<NavigationState> = store => {
  store.on('@init', () => ({
    isHidden: false,
    path: ''
  }))

  store.on('navigation/isHidden', (state, isHidden: boolean) => ({
    isHidden
  }))
}
