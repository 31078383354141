// @flow
import Axios from 'axios'
import { store } from 'src/state/store'
import Logo from 'src/images/logos/umich.png'

export const UNIVERSITY_LOGO = Logo

export const SITE_ID = 24
export const API_DOMAIN = 'https://campusvisitorguides.com/umich'

export const DOMAIN = 'https://campusvisitorguides.com/umich'
export const API_URL = process.env.NODE_ENV === 'development' ? '' : API_DOMAIN

export const UNIVERSITY_NAME = 'University of Michigan'

export const UNIVERSITY_COORDINATES = { lat: 42.278046, lng: -83.73822 }

// Add a response interceptor
Axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    //$FlowFixMe
    if (error.response?.status === 404) {
      store.dispatch('error/404')
    }
    //$FlowFixMe
    if (error.response?.status === 401) {
      store.dispatch('error/401')
    }
    return Promise.reject(error)
  }
)
