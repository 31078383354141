/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useState, useEffect } from 'react'
import type { LocationState } from 'src/state/locations'
import type { TourState } from 'src/state/tours'
import type { MediaState } from 'src/state/media'
import { useParams, useHistory } from 'react-router-dom'
import useStoreon from 'storeon/react'
import type { Tour } from 'src/utils/types'
import { useAnalytics } from 'src/hooks/useAnalytics'
import {
  FullScreenLoading,
  AddToTourModal,
  ExploreDetails
} from '@momentous/cea-component-library'

export const ExploreDetailsPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const [editTourVariant, setEditTourVariant] = useState('')
  const { analytics } = useAnalytics()

  const {
    dispatch,
    locations,
    isLoadingLocations,
    customTours,
    media,
    isLoadingMedia
  } = useStoreon<LocationState & TourState & MediaState>(
    'locations',
    'isLoadingLocations',
    'customTours',
    'media',
    'isLoadingMedia'
  )

  const [selectedTour, setSelectedTour] = useState<Tour | null>(customTours[0])

  useEffect(() => {
    dispatch('locations/load')
    dispatch('media/load', id)
    dispatch('tours/load')
  }, [dispatch])

  // eslint-disable-next-line eqeqeq
  const locationItem = locations.find(location => location.id == id)

  const isLoading = isLoadingLocations || isLoadingMedia

  return (
    <>
      <FullScreenLoading loading={isLoading} />
      <ExploreDetails
        media={media}
        locationItem={locationItem}
        onBack={() => history.goBack()}
        isLoading={isLoading}
        onAddClick={() => setEditTourVariant('add')}
        onRemoveClick={() => setEditTourVariant('remove')}
        onMediaClick={mediaId => {
          history.push(`/explore/media/${String(id)}`)
        }}
      />
      <AddToTourModal
        variant={editTourVariant}
        locationId={locationItem?.id}
        tours={customTours}
        isOpen={!!editTourVariant}
        onChange={val => setSelectedTour(val)}
        onAdd={() => {
          analytics.logEvent('location_added_to_tour', {
            location_name: locationItem?.name
          })
          dispatch('tours/add-location', {
            tourName: selectedTour?.title,
            location: locationItem
          })
          setEditTourVariant('')
        }}
        onRemove={() => {
          dispatch('tours/remove-location', {
            tourName: selectedTour?.title,
            location: locationItem
          })
          setEditTourVariant('')
        }}
        onClose={() => {
          setEditTourVariant('')
        }}
      />
    </>
  )
}
