// @flow
export const useAnalytics = () => {
  let analytics = null
  const localLogEvent = (eventName: string, value: Object) => {
    console.log('*********ANALYTICS EVENT***********')
    console.log(eventName)
    console.log(value)
    console.log('*********END EVENT***********')
  }
  if (window.firebase) {
    analytics = window.firebase.analytics()
  }
  return {
    analytics: analytics || { logEvent: localLogEvent }
  }
}
