// @flow
import type { MenuItem } from 'src/utils/types'
import type { Module } from 'storeon'
import { getMenus } from 'src/services/wordpress'
import { transformMenus } from 'src/utils/transformers'

export type MenuState = {
  menus: MenuItem[],
  isLoadingMenus: boolean,
  loadMenusError: string
}

export const menuModule: Module<MenuState> = store => {
  store.on('@init', () => ({
    menus: [],
    isLoadingMenus: false,
    loadMenusError: ''
  }))

  store.on('menus/load', async state => {
    try {
      if (!state.menus.length) {
        store.dispatch('menus/isLoading')
        const { data } = await getMenus()
        const menus = transformMenus(data.items)
        store.dispatch('menus/loaded', menus)
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('menus/isLoading', state => ({
    isLoadingMenus: true
  }))
  store.on('menus/loaded', (state, menuItems: MenuItem[]) => ({
    menus: menuItems,
    isLoadingMenus: false
  }))
}
