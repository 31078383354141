/* eslint-disable no-unused-expressions */
// @flow
import type { Tour, Location } from 'src/utils/types'
import type { Module } from 'storeon'
import { getDefaultTours } from 'src/services/wordpress'
import { transformDefaultTours } from 'src/utils/transformers'

export type AddLocationParams = {
  tourName: string,
  location: Location
}

export type SetActiveTourParams = {
  activeTour: Tour,
  destination: Location | null
}

export type TourState = {
  defaultTours: Tour[],
  customTours: Tour[],
  isLoadingDefaultTours: boolean,
  loadDefaultToursError: string,
  activeTour?: Tour,
  destination: Location | null
}

export const tourModule: Module<TourState> = store => {
  store.on('@init', state => ({
    defaultTours: [],
    customTours: [],
    destination: null,
    isLoadingDefaultTours: false,
    loadDefaultToursError: ''
  }))
  store.on('tours/load', async state => {
    try {
      if (!state.defaultTours.length) {
        store.dispatch('tours/isLoading')
        const { data } = await getDefaultTours()
        const tours = transformDefaultTours(data)
        store.dispatch('tours/loaded', tours)
      }
    } catch (err) {
      console.error(err)
    }
  })
  store.on('tours/isLoading', state => ({ isLoadingDefaultTours: true }))
  store.on('tours/loaded', (state, defaultTours: Tour[]) => ({
    defaultTours,
    isLoadingDefaultTours: false
  }))
  store.on('tours/save', (state, tour: Tour) => {
    return {
      customTours: saveTour(tour, state.customTours)
    }
  })
  store.on('tours/add-location', (state, data: AddLocationParams) => ({
    customTours: addLocation(data.tourName, data.location, state.customTours)
  }))
  store.on('tours/remove-location', (state, data: AddLocationParams) => ({
    customTours: removeLocation(data.tourName, data.location, state.customTours)
  }))
  store.on('tours/set-active', (state, data: SetActiveTourParams) => ({
    activeTour: data.activeTour,
    destination: data.destination
  }))
}

export const saveTour = (savedTour: Tour, tours: Tour[]): Tour[] => {
  const index = tours.findIndex(tour => tour.title === savedTour.title)
  if (index > -1) {
    const tourCopy = [...tours]
    tourCopy[index] = savedTour
    return tourCopy
  }
  return tours.concat(savedTour)
}

export const addLocation = (
  tourName: string,
  loc: Location,
  tours: Tour[]
): Tour[] => {
  const toursCopy = [...tours]
  toursCopy.find(tour => tour.title === tourName)?.locations.push(loc)
  return toursCopy
}

export const removeLocation = (
  tourName: string,
  loc: Location,
  tours: Tour[]
): Tour[] => {
  const toursCopy = [...tours]
  const tour = toursCopy.find(tour => tour.title === tourName)
  const locations = tour?.locations.filter(location => location.id !== loc.id)
  if (tour) {
    tour.locations = locations || []
  } else {
    console.error(`${tourName} does not exist`)
  }
  return toursCopy
}
