// @flow
import createStore from 'storeon'
import persistState from '@storeon/localstorage'
import type { MenuState } from 'src/state/menus'
import { menuModule } from 'src/state/menus'
import type { LocationState } from 'src/state/locations'
import { locationModule } from 'src/state/locations'
import type { TourState } from 'src/state/tours'
import { tourModule } from 'src/state/tours'
import type { MediaState } from 'src/state/media'
import { mediaModule } from 'src/state/media'
import type { NavigationState } from 'src/state/navigation'
import { navigationModule } from 'src/state/navigation'
import type { ErrorState } from 'src/state/errors'
import { errorModule } from 'src/state/errors'
import type { CategoryState } from 'src/state/categories'
import { categoryModule } from 'src/state/categories'
export type State = ErrorState &
  MenuState &
  LocationState &
  TourState &
  MediaState &
  NavigationState &
  CategoryState

export const store = createStore<any>([
  errorModule,
  menuModule,
  locationModule,
  tourModule,
  mediaModule,
  navigationModule,
  categoryModule,
  persistState<State>([
    'menus',
    'path',
    'locations',
    'defaultTours',
    'customTours',
    'media'
  ]),
  process.env.NODE_ENV === 'development' && require('storeon/devtools')
])
