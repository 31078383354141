// @flow
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import type { MediaState } from 'src/state/media'
import { MediaDetails } from '@momentous/cea-component-library'
import useStoreon from 'storeon/react'
import { useEffect } from 'react'

export const MediaDetailsPage = () => {
  const { id, mediaId } = useParams()
  const history = useHistory()

  const { dispatch, media, isLoadingMedia } = useStoreon<MediaState>(
    'media',
    'isLoadingMedia'
  )

  const initialMediaItem = media.find(media => media.id === mediaId)

  useEffect(() => {
    dispatch('media/load', id)
    // mediaId invalid, probably a bad link. throw a 404 error
    if (!initialMediaItem && !isLoadingMedia) {
      dispatch('error/404')
    }
  }, [dispatch, id, initialMediaItem, isLoadingMedia])

  return (
    <MediaDetails
      media={media}
      isLoading={isLoadingMedia}
      initialMedia={initialMediaItem}
      onBack={() => history.goBack()}
    />
  )
}
