// @flow
import React, { useEffect, useMemo } from 'react'
// import { useParams } from 'react-router-dom'
import { ExploreMedia } from '@momentous/cea-component-library'
import { useHistory, useParams } from 'react-router-dom'
import type { MediaState } from 'src/state/media'
import type { LocationState } from 'src/state/locations'

import useStoreon from 'storeon/react'

export const ExploreMediaPage = () => {
  const { id } = useParams()
  const history = useHistory()

  const {
    dispatch,
    media,
    locations,
    isLoadingMedia,
    isLoadingLocations
  } = useStoreon<MediaState & LocationState>(
    'media',
    'locations',
    'isLoadingMedia',
    'isLoadingLocations'
  )

  useEffect(() => {
    dispatch('media/load', id)
    dispatch('locations/load')
  }, [dispatch, id])

  const location = useMemo(
    // eslint-disable-next-line eqeqeq
    () => locations.find(loc => loc.id == media[0].locationId),
    [locations, media]
  )

  return (
    <ExploreMedia
      media={media}
      isLoading={isLoadingMedia || isLoadingLocations}
      locationName={location?.name}
      onMediaClick={mediaId =>
        history.push(`/explore/media/${location?.id}/details/${mediaId}`)
      }
      onBack={() => history.goBack()}
    />
  )
}
