// @flow
import React from 'react'
import type { ErrorState } from 'src/state/errors'
import { useHistory } from 'react-router-dom'
import useStoreon from 'storeon/react'
import { Header, Content, ErrorScreen } from '@momentous/cea-component-library'

export const ErrorPage = () => {
  const history = useHistory()

  const { dispatch, showError, errorMessage } = useStoreon<ErrorState>(
    'showError',
    'errorMessage'
  )

  return (
    <>
      <Header
        onBack={() => {
          history.replace('/')
          dispatch('error/reset')
        }}
        title="Back to Main Screen"
        variant="DEFAULT"
      />
      <Content
        title="Error Message"
        description="Oops, looks like there was a bit of an issue."
      >
        <ErrorScreen visible={showError} message={errorMessage} />
      </Content>
    </>
  )
}
