// @flow
import React, { useEffect } from 'react'
import useStoreon from 'storeon/react'
import { useParams, useHistory } from 'react-router-dom'
import type { MenuState } from 'src/state/menus'
import {
  FullScreenLoading,
  ContentMenu
} from '@momentous/cea-component-library'
import { UNIVERSITY_LOGO, UNIVERSITY_NAME } from 'src/settings'

export const ContentMenuPage = () => {
  const { section } = useParams()
  const history = useHistory()
  const { dispatch, menus, isLoadingMenus } = useStoreon<MenuState>(
    'menus',
    'isLoadingMenus'
  )

  useEffect(() => {
    if (!menus.length) dispatch('menus/load')
  }, [dispatch, menus])

  return (
    <>
      <FullScreenLoading loading={isLoadingMenus} />
      <ContentMenu
        schoolIcon={UNIVERSITY_LOGO}
        universityName={UNIVERSITY_NAME}
        menuItems={menus}
        section={section || ''}
        onBack={() => history.goBack()}
      />
    </>
  )
}
