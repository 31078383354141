// @flow
import Axios from 'axios'
import type { Location, Tour, Media } from 'src/utils/types'
import { API_URL } from 'src/settings'

export type WPMenuItem = {
  ID: string,
  menu_item_parent: string,
  post_title: string,
  title: string,
  object_id?: string,
  slug: string,
  child_items?: WPMenuItem[]
}

type GetMenusResponse = {
  items: WPMenuItem[]
}

export const getMenus = () =>
  Axios.get<GetMenusResponse>(`${API_URL}/wp-json/menus/v1/menus/pwa-menu`)

export type WPLocation = {
  ...Location,
  cordinates: {
    lng: string,
    lat: string
  }
}

export const getLocationList = () =>
  Axios.get<WPLocation[]>(`${API_URL}/wp-json/cvg/v1/locations`)

export const getDefaultTours = () =>
  Axios.get<Tour[]>(`${API_URL}/wp-json/cvg/v1/tours`)

export const getMediaByLocation = (locationId: string) =>
  Axios.get<Media[]>(`${API_URL}/wp-json/cvg/v1/media/${locationId}`)

export const getCategories = () =>
  Axios.get<string[]>(`${API_URL}/wp-json/cvg/v1/categories`)
