// @flow

import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory
} from 'react-router-dom'
import useStoreon from 'storeon/react'
import {
  PageLayout,
  NavBar,
  AnimatedRoute,
  PushNotification
} from '@momentous/cea-component-library'
import { ExploreCategoriesPage } from 'src/pages/explore/Categories'
import { ExploreListPage } from 'src/pages/explore/ExploreList'
import { ExploreDetailsPage } from 'src/pages/explore/ExploreDetails'
import { ExploreMediaPage } from 'src/pages/explore/ExploreMedia'
import { MediaDetailsPage } from 'src/pages/explore/MediaDetails'
import { TourListPage } from 'src/pages/tours/TourList'
import { EditTourPage } from 'src/pages/tours/EditTour'
import { ContentMenuPage } from 'src/pages/menu/ContentMenu'
import { ContentViewPage } from 'src/pages/menu/ContentView'
import { AnimatePresence } from 'framer-motion'
import { useAnalytics } from 'src/hooks/useAnalytics'
import type { NavigationState } from 'src/state/navigation'
import type { ErrorState } from 'src/state/errors'
import type { TourState } from 'src/state/tours'
import { ErrorPage } from 'src/pages/Error'

export const Routes = () => {
  // app only runs on devices with touch enabled
  const isMobile =
    'ontouchstart' in window.document.documentElement &&
    window.matchMedia('only screen and (max-width: 1024px)').matches

  if (!isMobile) {
    window.location.href = 'https://campusvisitorguides.com/dev/'
  }
  return (
    <React.Fragment>
      <Router>
        <PageLayout>
          <AppRoutes />
          <NavBarWithHistory />
        </PageLayout>
      </Router>
    </React.Fragment>
  )
}

const AppRoutes = () => {
  const { analytics } = useAnalytics()
  const history = useHistory()
  const { dispatch, showError, activeTour } = useStoreon<
    ErrorState & TourState
  >('activeTour', 'showError')

  useEffect(() => {
    if (showError) {
      history.push('/error')
    }
  }, [history, showError])

  analytics.logEvent('current_view') //logs already attach page info, so just send an empty one
  return (
    <Route
      render={({ location }) => (
        <AnimatePresence initial={false}>
          <PushNotification
            visible={!!activeTour && location.pathname !== '/tours'}
            message="you have a tour in progress."
            primaryText="Back to Tour"
            secondaryText="Stop Tour"
            primaryAction={() => {
              history.push('/tours')
            }}
            secondaryAction={() => {
              dispatch('tours/set-active', {
                activeTour: null,
                destination: null
              })
            }}
          />
          <Switch location={location} key={location.key}>
            <AnimatedRoute exact path="/error" component={ErrorPage} />
            <AnimatedRoute exact path="/" component={ExploreCategoriesPage} />
            <AnimatedRoute
              exact
              path="/explore/list/:initialCategory?"
              component={ExploreListPage}
            />
            <AnimatedRoute
              exact
              path="/explore/map"
              component={ExploreListPage}
            />
            <AnimatedRoute
              exact
              path="/explore/location/:id"
              component={ExploreDetailsPage}
            />
            <AnimatedRoute
              exact
              path="/explore/media/:id"
              component={ExploreMediaPage}
            />
            <AnimatedRoute
              exact
              path="/explore/media/:id/details/:mediaId"
              component={MediaDetailsPage}
            />
            <AnimatedRoute exact path="/tours" component={TourListPage} />

            <AnimatedRoute
              exact
              path="/tours/edit/:id"
              component={EditTourPage}
            />

            <AnimatedRoute
              exact
              path="/menu/:section?"
              component={ContentMenuPage}
            />
            <AnimatedRoute
              exact
              path="/menu/:section/:postSlug"
              component={ContentViewPage}
            />
            <AnimatedRoute
              exact
              path="/contact/:postSlug"
              component={ContentViewPage}
            />
          </Switch>
        </AnimatePresence>
      )}
    />
  )
}

const NavBarWithHistory = () => {
  const history = useHistory()
  const { dispatch, isHidden } = useStoreon<NavigationState>('path', 'isHidden')
  const onNavSelect = (next, current) => {
    dispatch('error/reset')
    switch (next) {
      case 0:
        history.push('/', { last: current, current: next })
        break
      case 1:
        history.push('/tours', { last: current, current: next })
        break
      case 2:
        history.push('/contact/resources', {
          last: current,
          current: next
        })
        break
      case 3:
        history.push('/menu', { last: current, current: next })
        break
      default:
        history.push('/', { last: current, current: next })
    }
  }
  return <NavBar onSelect={onNavSelect} hide={isHidden} shrink />
}

export default Routes
